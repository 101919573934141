@import url("https://fonts.googleapis.com/css2?family=Arvo&family=Montserrat&display=swap");

:root {
  --font-body: "Montserrat", sans-serif;
  --font-display: "Arvo", monospace;

  --color-light: #fbfaf8;
  --color-light-grey: #e8e6e3;
  --color-dark-grey: #506686;
  --color-dark: #0c1727;
  --color-primary: #ff9233;
  --color-dark-primary: #f05600;
  --color-accent: #95e1da;
  --color-dark-accent: #0f8075;

  --bg-cherry: rgba(255, 119, 119, 0.3);
  --bg-orange: rgba(255, 168, 119, 0.2);
  --bg-lemon: rgba(255, 225, 119, 0.3);
  --bg-lime: rgba(211, 255, 119, 0.3);
  --bg-kiwi: rgba(119, 255, 133, 0.3);
  --bg-jelly: rgba(119, 239, 255, 0.3);
  --bg-indigo: rgba(119, 157, 255, 0.3);
  --bg-violet: rgba(154, 119, 255, 0.3);
  --bg-peach: rgba(255, 119, 225, 0.3);
}

body {
  margin: 0;
  font-family: var(--font-body);
  font-size: 18px;
  -webkit-font-smoothing: antialiased;

  margin: 0;
  padding: 0;
  overflow: auto;

  background-color: var(--color-light);

  /* background-image: url("../public/bg-tl.png"), url("../public/bg-right.png");
  background-position: 0 0, top 250px right;
  background-repeat:no-repeat, no-repeat;

  background-size: 300px 300px, 300px 300px; */
}
/* 
@media (min-width: 768px) {
  body {
    background-image: url("../public/bg-tl@2x.png"), url("../public/bg-right@2x.png");
    background-size: 600px 600px, 600px 600px;
  }
} */

a, a:visited {
  color: var(--color-dark-accent);
}

a:hover, a:focus {
  color: var(--color-primary);
}