/*********************************************
  * GLOBAL STYLES
  *********************************************/

@import url("https://fonts.googleapis.com/css2?family=Arvo&family=Montserrat&display=swap");

:root {
  --lynx-light0: #f4f6f5;
  --lynx-light1: #f2f2f3;
  --lynx-dark1: #1e0d3f;
  --lynx-grey0: #d1d6db;
  --lynx-grey1: #c0c6c9;
  --lynx-grey2: #aab2b6;
  --lynx-grey3: #666971;
  --lynx-primary: #3396fa;
  --lynx-primaryLight: rgba(51, 150, 250, 0.25);
  --lynx-green0: #6fc1b2;
  --lynx-green1: #0ac29f;
  --lynx-orange0: #f2ab73;
  --lynx-orange1: #ea9a5c;
  --lynx-yellow: #f5cd39;
  --lynx-purple0: #9b7cda;
  --lynx-purple1: #8f5bf5;

  --background-color: #f4f6f5;
  --main-font-size: 26px;
  --main-color: #1e0d3f;
  --block-margin: 20px;
  --heading-margin: 0 0 20px 0;
  --heading-color: #1e0d3f;
  --heading-line-height: 1.2;
  --heading-letter-spacing: -0.03em;
  --heading-text-transform: none;
  --heading-text-shadow: none;
  --heading-font-weight: normal;
  --heading1-text-shadow: none;
  --heading1-size: 3.77em;
  --heading2-size: 2.11em;
  --heading3-size: 1.55em;
  --heading4-size: 1em;
  --code-font: monospace;
  --link-color: #8f5bf5;
  --link-color-hover: #9b7cda;
  --selection-background-color: #3396fa;
  --selection-color: #fff;
}

.reveal-viewport {
  background: var(--background-color);
  background-color: var(--background-color);
  background-size: 100px 100px, cover;
  background-repeat: repeat, no-repeat;
  background-image: url("../../public/lynx_slides_bg_grid.png"),
    url("../../public/lynx_slides_bg_lights.png");
}

@media screen and (min-width: 1920px) {
  .reveal-viewport {
    background-image: url("../../public/lynx_slides_bg_grid@2x.png"),
      url("../../public/lynx_slides_bg_lights@2x.png");
  }
}

.reveal {
  font-family: var(--font-body);
  font-size: var(--main-font-size);
  font-weight: normal;
  color: var(--main-color);
}

.reveal ::selection {
  color: var(--selection-color);
  background: var(--selection-background-color);
  text-shadow: none;
}

.reveal ::-moz-selection {
  color: var(--selection-color);
  background: var(--selection-background-color);
  text-shadow: none;
}

.reveal .slides section,
.reveal .slides section > section {
  line-height: 1.3;
  font-weight: inherit;
}

.reveal .page-only {
  display: none !important;
}

/*********************************************
  * HEADERS
  *********************************************/
.reveal h1,
.reveal h2,
.reveal h3,
.reveal h4,
.reveal h5,
.reveal h6 {
  margin: 0 0 20px 0;
  color: var(--heading-color);
  font-family: var(--font-display);
  font-weight: normal;
  line-height: 1.2;
  letter-spacing: -0.03em;
  text-transform: none;
  text-shadow: none;
  word-wrap: break-word;
}

.reveal h1 {
  font-size: 3.77em;
}

/* .reveal h2 {
   font-size: 2.11em; } */

.reveal h2,
.reveal h3,
.reveal h4 {
  font-size: 1.55em;
}

/* .reveal h4 {
   font-size: 1em; } */

.reveal h1 {
  text-shadow: none;
}

/*********************************************
  * OTHER
  *********************************************/
.reveal p {
  margin: 20px 0;
  line-height: 1.3;
  text-align: left;
}

.reveal p.center {
  text-align: center;
}

.reveal p.right {
  text-align: right;
}

.reveal .font-blue,
.page .font-blue {
  color: #7cafc2;
}
.reveal .font-pink,
.page .font-pink {
  color: #d5839d;
}
.reveal .font-green,
.page .font-green {
  color: #a1b56c;
}

/* Ensure certain elements are never larger than the slide itself */
.reveal img,
.reveal video,
.reveal iframe {
  max-width: 95%;
  max-height: 95%;
}

.reveal strong,
.reveal b {
  font-weight: bold;
}

.reveal em {
  color: var(--link-color);
  font-style: normal;
}

.reveal ol,
.reveal dl,
.reveal ul {
  display: inline-block;
  text-align: left;
  margin: 0 0 0 1em;
}

.reveal ol {
  list-style-type: decimal;
}

.reveal ul {
  list-style-type: disc;
}

.reveal ol li,
.reveal ul li {
  margin-left: 2ch;
}

.reveal ul ul {
  list-style-type: square;
}

.reveal ul ul ul {
  list-style-type: circle;
}

.reveal ul ul,
.reveal ul ol,
.reveal ol ol,
.reveal ol ul {
  display: block;
  margin-left: 40px;
}

.reveal dt {
  font-weight: bold;
}

.reveal dd {
  margin-left: 40px;
}

.reveal blockquote {
  display: block;
  position: relative;
  width: 70%;
  margin: 20px auto;
  padding: 10px;
  font-style: italic;
  border-left: 6px solid var(--selection-background-color);
  background: rgba(255, 255, 255, 0.05);
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.2);
}

.reveal blockquote p:first-child,
.reveal blockquote p:last-child {
  display: inline-block;
}

.reveal q {
  font-style: italic;
}

.reveal pre {
  display: block;
  position: relative;
  width: 90%;
  margin: 20px auto;
  text-align: left;
  font-size: 0.55em;
  font-family: monospace;
  line-height: 1.2em;
  word-wrap: break-word;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.15);
}

.reveal code {
  font-family: monospace;
  text-transform: none;
  color: var(--link-color-hover);
}

.reveal pre code {
  display: block;
  padding: 5px;
  overflow: auto;
  max-height: 400px;
  word-wrap: normal;
}

.reveal table {
  margin: auto;
  border-collapse: collapse;
  border-spacing: 0;
}

.reveal table th {
  font-weight: bold;
}

.reveal table th,
.reveal table td {
  text-align: left;
  padding: 0.2em 0.5em 0.2em 0.5em;
}

.reveal table th[align="center"],
.reveal table td[align="center"] {
  text-align: center;
}

.reveal table th[align="right"],
.reveal table td[align="right"] {
  text-align: right;
}

.reveal sup {
  vertical-align: super;
  font-size: smaller;
}

.reveal sub {
  vertical-align: sub;
  font-size: smaller;
}

.reveal small {
  display: inline-block;
  font-size: 0.6em;
  line-height: 1.2em;
  vertical-align: top;
}

.reveal small * {
  vertical-align: top;
}

.reveal img {
  margin: 20px 0;
}

/*********************************************
  * LINKS
  *********************************************/
.reveal a {
  color: var(--link-color);
  text-decoration: underline;
  transition: color 0.15s ease;
}

.reveal a:hover {
  color: var(--link-color-hover);
  text-shadow: none;
  border: none;
}

.reveal .roll span:after {
  color: #fff;
  background: var(--link-color);
}

/*********************************************
  * Frame helper
  *********************************************/
.reveal .r-frame {
  border: 4px solid #eee;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
}

.reveal a .r-frame {
  transition: all 0.15s linear;
}

.reveal a:hover .r-frame {
  border-color: var(--selection-color);
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.55);
}

/*********************************************
  * NAVIGATION CONTROLS
  *********************************************/
.reveal .controls {
  color: var(--link-color);
}

/*********************************************
  * PROGRESS BAR
  *********************************************/
.reveal .progress {
  background: rgba(0, 0, 0, 0.2);
  color: var(--selection-color);
}

/*********************************************
  * PRINT BACKGROUND
  *********************************************/
@media print {
  .backgrounds {
    background-color: #111;
  }
}

/* Shortcut menu */
#shortcuts {
  position: fixed;
  top: 10px;
  left: 20px;
  font-family: var(--font-body);
  width: calc(100% - 100px);

  display: flex;
  justify-content: space-evenly;
}

#shortcuts.on-page {
  background-color: #fefefe;
  width: 100%;
  top: 0;
  left: 0;
  padding-top: 10px;
  padding-bottom: 10px;
  box-shadow: rgba(0, 0, 0, 0.2) 0 1px 8px 2px;
}

@media only screen and (max-device-width: 800px) {
  #shortcuts.on-page {
    display: none;
  }
}

#shortcuts a {
  display: inline-block;
  color: var(--link-color);
  text-decoration: underline;
  transition: color 0.15s ease;
  text-transform: uppercase;
}

#shortcuts a:hover {
  color: var(--link-color-hover);
}

.reveal .small-on-slides {
  font-size: 0.6em;
}
