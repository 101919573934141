@import url("https://fonts.googleapis.com/css2?family=Arvo&family=Montserrat&display=swap");

:root {
  --lynx-light0: #f4f6f5;
  --lynx-light1: #f2f2f3;
  --lynx-dark1: #1e0d3f;
  --lynx-grey0: #d1d6db;
  --lynx-grey1: #c0c6c9;
  --lynx-grey2: #aab2b6;
  --lynx-grey3: #666971;
  --lynx-primary: #3396fa;
  --lynx-primaryLight: rgba(51, 150, 250, 0.25);
  --lynx-green0: #6fc1b2;
  --lynx-green1: #0ac29f;
  --lynx-orange0: #f2ab73;
  --lynx-orange1: #ea9a5c;
  --lynx-yellow: #f5cd39;
  --lynx-purple0: #9b7cda;
  --lynx-purple1: #8f5bf5;

  --background-color: #f4f6f5;
  --main-font: "Montserrat", sans-serif;
  --main-font-size: 26px;
  --main-color: #1e0d3f;
  --block-margin: 20px;
  --heading-margin: 0 0 20px 0;
  --heading-font: "Arvo", monospace;
  --heading-color: #1e0d3f;
  --heading-line-height: 1.2;
  --heading-letter-spacing: -0.03em;
  --heading-text-transform: none;
  --heading-text-shadow: none;
  --heading-font-weight: normal;
  --heading1-text-shadow: none;
  --heading1-size: 3.77em;
  --heading2-size: 2.11em;
  --heading3-size: 1.55em;
  --heading4-size: 1em;
  --code-font: monospace;
  --link-color: #8f5bf5;
  --link-color-hover: #9b7cda;
  --selection-background-color: #3396fa;
  --selection-color: #fff;
}
/* Page Styling */
main {
  font-family: var(--font-body);
  font-size: 1.0em;
  font-weight: normal;
}

main .slides-only {
  display: none !important;
}

main section {
  width: 100%;
  margin: 0 auto;
  margin-bottom: 40px;
  box-sizing: border-box;
}

main section:first-child {
  padding-top: 0;
}

/*
@media only screen and (min-device-width: 800px) {
  main section {
    max-width: 1000px;
    width: 80%;
    padding: 20px 100px;
    box-sizing: content-box;
  }
}
*/
@media print {
  main section {
    page-break-after: always;
  }

  main aside {
    color: magenta;
  }
}


main section.before-change {
  padding-bottom: 70px;
}

main section.dark {
  background-color: var(--background-color);
  color: var(--main-color);
}

main section[id] {
  padding-top: 20px;
}

main section:last-child {
  padding-bottom: 100px;
}

main section > h2,
main section > h3 {
  margin-top: 50px;
}

main section[id] > h2,
main section[id] > h3,
main section[id] > h4 {
  margin-top: 70px;
}

main h1,
main h2,
main h3,
main h4 {
  margin: 0 0 20px 0;
  font-family: var(--font-display);
  font-weight: normal;
  line-height: 1.2;
  text-transform: none;
  text-shadow: none;
  word-wrap: break-word;
}

main h4 {
  font-size: 1.2em;
}

main h3 {
  font-size: 1.4em;
}

main h2 {
  font-size: 1.6em;
}

main h1 {
  font-size: 2em;
  text-align: center;
}

main p,
main aside.notes {
  margin-top: 0.5em;
  margin-bottom: 0.5em;

  font-size: 1.0em;
}

main small {
  font-size: 1.0em;
}

main table {
  margin: auto;
  border-collapse: collapse;
  border-spacing: 0;
  max-width: 100%;
}


main section img,
main section video,
main section iframe {
  max-width: 95%;
  max-height: 95%;
  margin: 20px auto;
}


main em {
  color: var(--lynx-primary);
  font-style: normal;
}

main section.dark em {
  color: var(--lynx-green0);
}

main ul,
main ol {
  margin-left: 2ch;
}

main table th {
  font-weight: bold;
}

main table td {
  padding: 0.5em;
}

main blockquote {
  display: block;
  position: relative;
  width: 70%;
  margin: 20px auto;
  padding: 10px;
  font-style: italic;
  border-left: 6px solid var(--link-color-hover);
  background: rgba(255, 255, 255, 0.05);
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.2);
}

.reveal .small-on-slides {
  font-size: 0.6em;
}

main aside.notes {
  display: inherit;
}

main aside.notes.inline {
  display: inline;
}

main aside.notes.hide {
  display: none;
}

main code {
  font-family: monospace;
  text-transform: none;
  background-color: var(--main-color);
  color: var(--background-color);
  padding: 2px 1ch;
}

main section a {
  color: var(--link-color);
  text-decoration: underline;
  transition: color 0.15s ease;
}

main a:hover {
  color: var(--link-color-hover);
  text-shadow: none;
  border: none;
}

main section.dark a {
  color: var(--link-color-hover);
}

main section.dark a:hover {
  color: var(--link-color);
}
